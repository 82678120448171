<template>
  <div class="ma-4 rounded-image">
    <ImageWithDescription
      :readonly="readonly"
      :value="value"
      :hideDownloadButtons="false"
      @delete="onDeleteClicked"
      @open="onOpenClicked"
      @edit="onEditClicked"
      @openPdf="onOpenClickedPdf"
      miniature
    ></ImageWithDescription>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onDeleteClicked() {
      this.$emit("delete");
    },
    onOpenClicked() {
      this.$emit("open");
    },
    onEditClicked() {
      this.$emit("edit");
    },
    onOpenClickedPdf() {
      this.$emit("openPdf");
    }
  },
  components: {
    ImageWithDescription: () => import("../components/ImageWithDescription")
  }
};
</script>

<style scoped class="css">
.rounded-image {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -khtml-border-radius: 15px;
  border-radius: 8px;
  border: none;
  overflow: hidden;
}
</style>
